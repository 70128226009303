import Head from './Head';
import Education from './Education';
import Contact from './Contact';
import Experience from './Experience';

function App() {
  return (
    <>
      <Head />
      <Experience />
      <Education />
      <Contact />
    </>
  );
}

export default App;
