const data = [
  {
    company: 'Amazon Web Services',
    jobTitle: 'SDE I',
    dates: 'June 2021 - Present',
    location: 'Arlington, VA'
  },
  {
    company: 'Northrop Grumman',
    jobTitle: 'SWE',
    dates: 'June 2019 - June 2021',
    location: 'Annapolis Junction, MD'
  }
]

export default data;