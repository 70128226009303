import React from 'react';
import './index.css';

const Head = () => {
  return (
    <div className='my-intro'>
      <div className='container'>
        <div className='intro-text'>
          <h1>Brian Kennedy</h1>
          <h2>software developer</h2>
          <h3 className='line-3'>Arlington, VA</h3>

        </div>

      </div>
    </div>
  );
};

export default Head;
