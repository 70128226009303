const data = [
  {
    schoolName: 'The Johns Hopkins University',
    degree: 'Computer Science, M.S.',
    dates: '2019 - 2021'
  },
  {
    schoolName: 'University of Maryland, College Park',
    degree: 'Computer Science, B.S.',
    dates: '2016 - 2019'
  }
]

export default data;