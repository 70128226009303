import React from 'react';
import School from './School';
import './index.css';
import data from './data/schooldata';
import umd from './data/umd-transparent.png';
import jhu from './data/jhu-shield.png';

const Education = () => {

    return (
      <>
        <div className='education'>

            <div className='flex-school-container container'>
                <School data={data[0]} image={jhu} />
                <School data={data[1]} image={umd} />
            </div>
        </div>

        </>

    );
};

export default Education;
