import React from "react";
import "./index.css";

const School = ({ data, image }) => {
    const { schoolName, degree, dates } = data;

    return (
        <>
            <div className='flex-school-item'>
                <div>
                    <img src={image} alt="picutre" />
                </div>

                <div>
                    <h2 className='school-name'>{schoolName}</h2>
                </div>
                
                <div>
                    <span className='degree'> {degree} </span>
                    <span className='date'> {dates} </span>
                </div>

            </div>
        </>
    );
};



export default School;
