import React from "react";
import Work from './Work';

import "./index.css";
import data from './data/experiencedata';

const Experience = () => {
  return (
    <>
      <div className='experience'>
        <div className='container'>
          <div className="about-me">
            <p>
              I'm a software developer in the DC / NoVA area currently working at AWS HQ2 supporting Commerce Platform.
              In my free time I like to learn new frameworks, technologies, tools, etc., and also build
              out side projects. The best way to contact me is by sending me a message on LinkedIn.
          </p>
          </div>

          <hr className="line" />

          <Work data={data[0]} />
          <Work data={data[1]} />

          {/* <hr className="line" /> */}
        </div>
      </div>
    </>
  );
};

export default Experience;
