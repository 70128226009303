import React from "react";
import "./index.css";

const Work = ({ data }) => {
    const { company, jobTitle, dates, location } = data;

  return (
    <div className="flex-job-container">
        <div className="flex-item">
            <div className="job-title">{company}</div>
        </div>

        <div className="flex-item">
            <span className="work-position">{jobTitle}</span>
            <span className="work-date">{dates}</span>
        </div>

        <div className="flex-item">
          <span className="location item">{location}</span>
      </div>

    </div>
  );
};

export default Work;
