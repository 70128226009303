import React from "react";
import "./index.css";
import linkedin from "./data/linkedin-new.png";

const Contact = () => {
  return (
    <>
      <div className="contact">
        <div className="container">
          <a href="https://www.linkedin.com/in/brian-kennedy-3781b7143/">

          <img className="linkedin-logo" src={linkedin} alt="picutre" />
          </a>
          <p>Brian Kennedy &copy; 2021</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
